





































































































































































































































































































































































import { Component, Vue } from 'vue-property-decorator'
import { VePie } from 'v-charts'

@Component({
	components: {
		VePie,
	},
})
export default class Dashboard extends Vue {
	pagerHeader: any = {
		title: '仪表盘',
		desc: '数据统计看板。',
		breadcrumb: ['仪表盘'],
	}
	pieChart: any = {
		chartData: {
			columns: ['date', 'money'],
			rows: [],
		},
		chartSettings: {
			dimension: 'date',
			metrics: 'money',
			radius: 70,
			offsetY: 130,
			selectedMode: 'single',
		},
	}
    value: any = '0'
    time:any=''
    info = {}
	statisticsData = {}
	created() {
            this.gainData()
            this.gainDataType(this.value)
	}
    activated() {
            this.gainData()
            this.gainDataType(this.value)
    }
	handleClose(done: any) {
		this.$confirm('确认关闭？')
			.then(_ => {
				done()
			})
			.catch(_ => {})
	}

	async gainData() {
		try {
			let res = await this.$ajax.post(
				'/api/services/app/Statistics/QueryDashboardStatisticsData'
			)
			if (res.data.success) {
				this.info = res.data.result
				if (res.data.result.unHandledFeedbacks != 0) {
					this.$confirm(
						`您有${
							res.data.result.unHandledFeedbacks
						}条未处理的问题反馈`,
						'提示',
						{
							confirmButtonText: '马上去处理',
							cancelButtonText: '稍后',
							type: 'warning',
						}
					)
						.then(() => {
							this.$router.push('/member/questionback')
						})
						.catch(() => {})
				}
			}
		} catch (error) {}
	}
	async gainDataType(value: any) {
          let loading = this.$loading({
                lock: true,
                text: "加载中",
                spinner: "el-icon-loading",
                background: "rgba(0, 0, 0, 0.3)"
            });
		try {
			let res = await this.$ajax.post(
				'/api/services/app/Statistics/QueryDashboardDateStatisticsData',
				{
					statisticsType: value,
				}
			)
			if (res.data.success) {
				this.statisticsData = res.data.result
				this.pieChart.chartData.rows = [
					{ date: '微信', money: res.data.result.wechatOrderAmount },
					{
						date: '支付宝',
						money: res.data.result.alipayOrderAmount,
					},
				]
			}
        } catch (error) {}
        loading.close();
	}
	changeValue() {
          
        this.gainDataType(this.value)
        this.time=this.value
    }
}
